@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap");

$red: #17596D;
$black: #191919;
  
body, html{
  margin:0;
  padding:0;
  width:100%;
  height:100%;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.Logo-Size{
  width: 10%;
  @media (max-width: 599px) {
    width: 20%;
  }
  }
  a {
    color: black;
    cursor: pointer;
    text-decoration: none;
}
header {
  height: 100px;
  .inner-header {
    position: relative;
    z-index: 10;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 599px) {
      justify-content: end;
    }
    .logo a {
      font-weight: 700;
      font-size: 1rem;
      text-decoration: none;
      color: $black;
    }
    .menu {
      button {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        font-size: 0.8rem;
        mix-blend-mode: difference;
      }
    }
  }
  .hamburger-menu {
    display: none;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    .menu-secondary-background-color {
      background: $black;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
    .menu-layer {
      position: relative;
      background: $red;
      height: 100%;
      overflow: hidden;
      .menu-city-background {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-size: cover;
        background-repeat: no-repeat;
        animation-name: backgroundEffect;
        animation-duration: 30s;
        animation-iteration-count: infinite;
      }
      .wrapper {
        position: relative;
        .menu-links {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          top: 100px;
          nav {
            display: block;
            ul {
              margin: 0;
              padding: 0;
              @media (max-width: 599px) {
                margin-left: 15px;
              }
              li {
                list-style: none;
                font-size: 3.5rem;
                font-weight: 700;
                cursor: pointer;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 700px;
                @media (max-width: 599px) {
                height: 45px;
                }
                a {
                  position: absolute;
                  color: #fff;
                  text-decoration: none;
                  &:hover {
                    color: $black;
                  }
                  @media (max-width: 599px) {
                    font-size: 26px;
                  }
                }
              }
            }
          }
          .info {
            color: #fff;
            width: 300px;
            @media (max-width: 599px) {
            position: absolute;
            margin-top: 210px;
            margin-left: 15px;
            }
            h3 {
              font-size: 1.2rem;
              margin: 8px auto;
            }
            p {
              margin: 0 auto;
              font-size: 0.8rem;
            }
          }
        }
        .locations {
          position: absolute;
          bottom: -80px;
          color: #fff;
          margin-top: 16px;
          font-size: 0.8rem;
          font-weight: 600;
          @media (max-width: 599px) {
          bottom: -200px;
          }
          span {
            cursor: pointer;
            &:first-child {
              margin-left: 64px;
              @media (max-width: 599px) {
                margin-left: 34px;
              }
            }
            font-weight: 400;
            margin: 0 32px;
            transition: 0.3s ease-in-out;
            @media (max-width: 599px) {
              margin: 0 12px;
            }
            &:hover {
              color: #fff;
              background: $black;
              padding: 8px 24px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}


