@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,700i&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; }

.Logo-Size {
  width: 10%; }
  @media (max-width: 599px) {
    .Logo-Size {
      width: 20%; } }

a {
  color: black;
  cursor: pointer;
  text-decoration: none; }

header {
  height: 100px; }
  header .inner-header {
    position: relative;
    z-index: 10;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 599px) {
      header .inner-header {
        justify-content: end; } }
    header .inner-header .logo a {
      font-weight: 700;
      font-size: 1rem;
      text-decoration: none;
      color: #191919; }
    header .inner-header .menu button {
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      font-size: 0.8rem;
      mix-blend-mode: difference; }
  header .hamburger-menu {
    display: none;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%; }
    header .hamburger-menu .menu-secondary-background-color {
      background: #191919;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1; }
    header .hamburger-menu .menu-layer {
      position: relative;
      background: #17596D;
      height: 100%;
      overflow: hidden; }
      header .hamburger-menu .menu-layer .menu-city-background {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-animation-name: backgroundEffect;
                animation-name: backgroundEffect;
        -webkit-animation-duration: 30s;
                animation-duration: 30s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite; }
      header .hamburger-menu .menu-layer .wrapper {
        position: relative; }
        header .hamburger-menu .menu-layer .wrapper .menu-links {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          top: 100px; }
          header .hamburger-menu .menu-layer .wrapper .menu-links nav {
            display: block; }
            header .hamburger-menu .menu-layer .wrapper .menu-links nav ul {
              margin: 0;
              padding: 0; }
              @media (max-width: 599px) {
                header .hamburger-menu .menu-layer .wrapper .menu-links nav ul {
                  margin-left: 15px; } }
              header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li {
                list-style: none;
                font-size: 3.5rem;
                font-weight: 700;
                cursor: pointer;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 700px; }
                @media (max-width: 599px) {
                  header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li {
                    height: 45px; } }
                header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li a {
                  position: absolute;
                  color: #fff;
                  text-decoration: none; }
                  header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li a:hover {
                    color: #191919; }
                  @media (max-width: 599px) {
                    header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li a {
                      font-size: 26px; } }
          header .hamburger-menu .menu-layer .wrapper .menu-links .info {
            color: #fff;
            width: 300px; }
            @media (max-width: 599px) {
              header .hamburger-menu .menu-layer .wrapper .menu-links .info {
                position: absolute;
                margin-top: 210px;
                margin-left: 15px; } }
            header .hamburger-menu .menu-layer .wrapper .menu-links .info h3 {
              font-size: 1.2rem;
              margin: 8px auto; }
            header .hamburger-menu .menu-layer .wrapper .menu-links .info p {
              margin: 0 auto;
              font-size: 0.8rem; }
        header .hamburger-menu .menu-layer .wrapper .locations {
          position: absolute;
          bottom: -80px;
          color: #fff;
          margin-top: 16px;
          font-size: 0.8rem;
          font-weight: 600; }
          @media (max-width: 599px) {
            header .hamburger-menu .menu-layer .wrapper .locations {
              bottom: -200px; } }
          header .hamburger-menu .menu-layer .wrapper .locations span {
            cursor: pointer;
            font-weight: 400;
            margin: 0 32px;
            -webkit-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out; }
            header .hamburger-menu .menu-layer .wrapper .locations span:first-child {
              margin-left: 64px; }
              @media (max-width: 599px) {
                header .hamburger-menu .menu-layer .wrapper .locations span:first-child {
                  margin-left: 34px; } }
            @media (max-width: 599px) {
              header .hamburger-menu .menu-layer .wrapper .locations span {
                margin: 0 12px; } }
            header .hamburger-menu .menu-layer .wrapper .locations span:hover {
              color: #fff;
              background: #191919;
              padding: 8px 24px;
              border-radius: 4px; }

@-webkit-keyframes backgroundEffect {
  0% {
    background-position: 0% 0%; }
  25% {
    background-position: 40% 10%; }
  50% {
    background-position: 0 10%; }
  75% {
    background-position: 10% 40%; }
  100% {
    background-position: 0% 0%; } }

@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%; }
  25% {
    background-position: 40% 10%; }
  50% {
    background-position: 0 10%; }
  75% {
    background-position: 10% 40%; }
  100% {
    background-position: 0% 0%; } }

html body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; }

.hero {
  visibility: hidden;
  height: 100vh; }
  .hero .container {
    width: 1280px;
    min-width: 1280px;
    margin: 0 auto;
    height: 100%; }
    @media (max-width: 599px) {
      .hero .container {
        width: 100%;
        min-width: 100%; } }
    .hero .container .hero-inner {
      align-items: center;
      justify-content: space-between;
      height: 100vh; }
      @media (max-width: 599px) {
        .hero .container .hero-inner .hero-content {
          width: 0%; } }
      .hero .container .hero-inner .hero-content .hero-content-inner {
        color: #323232;
        margin: 0 auto; }
        @media (max-width: 599px) {
          .hero .container .hero-inner .hero-content .hero-content-inner {
            width: 400px; } }
        .hero .container .hero-inner .hero-content .hero-content-inner h1 {
          font-weight: 500;
          font-size: 32px;
          margin-bottom: 24px; }
          @media (max-width: 599px) {
            .hero .container .hero-inner .hero-content .hero-content-inner h1 {
              font-size: 22px; } }
          .hero .container .hero-inner .hero-content .hero-content-inner h1 .hero-content-line {
            margin: 0;
            height: 44px;
            overflow: hidden; }
        .hero .container .hero-inner .hero-content .hero-content-inner p {
          font-size: 14px;
          line-height: 24px;
          padding-right: 48px;
          margin-bottom: 56px; }
          @media (max-width: 599px) {
            .hero .container .hero-inner .hero-content .hero-content-inner p {
              font-size: 10px;
              padding-right: 96px; } }
        .hero .container .hero-inner .hero-content .hero-content-inner .btn-row {
          position: relative; }
          .hero .container .hero-inner .hero-content .hero-content-inner .btn-row:before {
            position: absolute;
            content: "";
            width: 56px;
            height: 1px;
            background: #dfdfdf;
            top: 50%;
            left: -68px; }
          .hero .container .hero-inner .hero-content .hero-content-inner .btn-row button {
            background: none;
            border: none;
            font-size: 12px;
            padding: 0;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 0.7px;
            display: flex;
            align-items: center;
            position: relative; }
            .hero .container .hero-inner .hero-content .hero-content-inner .btn-row button:focus {
              outline: none; }
            .hero .container .hero-inner .hero-content .hero-content-inner .btn-row button .arrow-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              background: #000000;
              height: 48px;
              width: 48px;
              border-radius: 100px;
              margin-left: 16px;
              left: 64px;
              -webkit-transform-origin: center center;
                      transform-origin: center center; }
      .hero .container .hero-inner .hero-images {
        width: 50%;
        height: 100vh;
        position: absolute; }
        .hero .container .hero-inner .hero-images .hero-images-inner .hero-image {
          position: absolute;
          overflow: hidden; }
          .hero .container .hero-inner .hero-images .hero-images-inner .hero-image.first {
            top: 100px;
            width: 85%;
            height: 100%; }
          .hero .container .hero-inner .hero-images .hero-images-inner .hero-image.second {
            top: 100px;
            left: 100%;
            width: 85%;
            height: 100%; }
          .hero .container .hero-inner .hero-images .hero-images-inner .hero-image img {
            position: absolute;
            width: 100%; }
      .hero .container .hero-inner .hero-images2 {
        width: 50%;
        height: 100vh;
        position: absolute;
        top: 150%; }
        .hero .container .hero-inner .hero-images2 .hero-images-inner .hero-image {
          position: absolute;
          overflow: hidden; }
          .hero .container .hero-inner .hero-images2 .hero-images-inner .hero-image.first {
            width: 85%;
            height: 100%; }
          .hero .container .hero-inner .hero-images2 .hero-images-inner .hero-image.second {
            left: 100%;
            width: 85%;
            height: 100%; }
          .hero .container .hero-inner .hero-images2 .hero-images-inner .hero-image img {
            position: absolute;
            width: 100%; }
      .hero .container .hero-inner .hero-images3 {
        width: 50%;
        height: 100vh;
        position: absolute; }
        .hero .container .hero-inner .hero-images3 .hero-images-inner .hero-image {
          position: absolute;
          overflow: hidden; }
          .hero .container .hero-inner .hero-images3 .hero-images-inner .hero-image.first {
            width: 85%;
            height: 100%; }
          .hero .container .hero-inner .hero-images3 .hero-images-inner .hero-image.second {
            left: 100%;
            width: 85%;
            height: 100%; }
          .hero .container .hero-inner .hero-images3 .hero-images-inner .hero-image img {
            position: absolute;
            width: 100%; }

main{
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .largeElements{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    }
    .smallElements{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    }
    .smallElements{
    width: 60px;
    height: 60px;
    background-color: blue;
    }
    .largeElements{
    width: 180px;
    height: 180px;
    background-color: black;
    }
.container {
  width: 1280px;
  min-width: 1280px; }
  .container .footer {
    bottom: 0;
    width: 100%; }
    .container .footer .Title-font {
      font-size: 1.5rem; }
      @media (max-width: 599px) {
        .container .footer .Title-font {
          font-size: 1.5rem; } }
  .container .footer-logo {
    margin-left: -52px !important; }
    @media (max-width: 599px) {
      .container .footer-logo {
        margin-left: -29px !important; } }
  .container .Logo-Size {
    width: 10%; }
    @media (max-width: 599px) {
      .container .Logo-Size {
        width: 20%; } }

.container {
  width: 1280px;
  min-width: 1280px;
  margin: 0 auto;
  height: 100%; }
  @media (max-width: 599px) {
    .container {
      width: 100%;
      min-width: 100%; } }
  .container .wrapper {
    padding: 0 48px; }
    @media (max-width: 599px) {
      .container .wrapper {
        padding: 0 0px 0 0px; } }
    .container .wrapper .home {
      display: flex;
      justify-content: center;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%; }
      .container .wrapper .home p {
        font-size: 0.75rem;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-weight: 500; }
      .container .wrapper .home h5 {
        font-size: 1.375rem;
        font-weight: 600; }
        @media (max-width: 599px) {
          .container .wrapper .home h5 {
            font-size: 16px;
            padding-right: 0px; } }
        .container .wrapper .home h5 ul {
          list-style-type: square;
          color: black; }
      .container .wrapper .home .Title-font {
        font-size: 4rem; }
        @media (max-width: 599px) {
          .container .wrapper .home .Title-font {
            font-size: 1.5rem; } }
      .container .wrapper .home .Title-font-2 {
        font-size: 4rem; }
        @media (max-width: 599px) {
          .container .wrapper .home .Title-font-2 {
            font-size: 1.5rem; } }
      .container .wrapper .home .Title-font-2:hover {
        text-decoration: underline; }
  .container .container2 .wrapper2 {
    width: 50%;
    float: right;
    margin-top: -500px; }
    @media (max-width: 599px) {
      .container .container2 .wrapper2 {
        width: 100%;
        margin-top: -720px; } }
  .container .container2 .compu::before {
    content: "";
    display: block;
    border-bottom: 1px solid transparent;
    margin: 395px 162px; }
  .container .container2 .a {
    position: absolute;
    height: 271px;
    background: #212121;
    margin: -770px 21px; }
    @media (max-width: 599px) {
      .container .container2 .a {
        margin: -770px 60px; } }
  .container .container2 .a::before {
    content: "</>";
    font-family: verdana;
    color: white;
    display: block;
    width: 201px;
    height: 21px;
    background: rgba(0, 0, 0, 0.3); }
  .container .container2 .b {
    position: absolute;
    height: 271px;
    background: #ebf4ff;
    margin: -770px 231px; }
    @media (max-width: 599px) {
      .container .container2 .b {
        margin: -500px 60px; } }
  .container .container2 .b::before {
    content: "";
    color: black;
    display: block;
    width: 201px;
    height: 21px;
    background: rgba(0, 0, 0, 0.3); }
  .container .container2 .l1 {
    position: absolute;
    width: 30px;
    height: 7px;
    background: #e35a5a;
    margin: 30px 21px;
    animation: l1 3s linear infinite;
    -moz-animation: l1 3s linear infinite;
    -webkit-animation: l1 3s linear infinite; }
  .container .container2 .l2 {
    position: absolute;
    width: 50px;
    height: 7px;
    background: #df9935;
    margin: 40px 21px;
    animation: l2 3s linear infinite;
    -moz-animation: l2 3s linear infinite;
    -webkit-animation: l2 3s linear infinite; }
  .container .container2 .l3 {
    position: absolute;
    width: 21px;
    height: 7px;
    background: white;
    margin: 50px 21px;
    animation: l3 3s linear infinite;
    -moz-animation: l3 3s linear infinite;
    -webkit-animation: l3 3s linear infinite; }
  .container .container2 .l4 {
    position: absolute;
    width: 70px;
    height: 7px;
    background: #9e9e9e;
    margin: 83px 19px;
    animation: l4 3s linear infinite;
    -moz-animation: l4 3s linear infinite;
    -webkit-animation: l4 3s linear infinite; }
  .container .container2 .l4::before {
    content: "";
    display: block;
    width: 70px;
    height: 7px;
    background: #9e9e9e;
    margin: 0px 90px;
    animation: l4 3s linear infinite;
    -moz-animation: l4 3s linear infinite;
    -webkit-animation: l4 3s linear infinite; }
  .container .container2 .l5 {
    position: absolute;
    width: 40px;
    height: 7px;
    background: #e35a5a;
    margin: 121px 21px;
    animation: l5 3s linear infinite;
    -moz-animation: l5 3s linear infinite;
    -webkit-animation: l5 3s linear infinite; }
  .container .container2 .l5::before {
    content: "";
    display: block;
    width: 40px;
    height: 7px;
    background: #df9935;
    margin: 0px 60px;
    animation: l5 3s linear infinite;
    -moz-animation: l5 3s linear infinite;
    -webkit-animation: l5 3s linear infinite; }
  .container .container2 .l5::after {
    content: "";
    display: block;
    width: 40px;
    height: 7px;
    background: #00aaef;
    margin: -7px 121px;
    animation: l5 3s linear infinite;
    -moz-animation: l5 3s linear infinite;
    -webkit-animation: l5 3s linear infinite; }
  .container .container2 .l6 {
    position: absolute;
    width: 70px;
    height: 7px;
    background: gray;
    margin: 152px 21px;
    animation: l6 3s linear infinite;
    -moz-animation: l6 3s linear infinite;
    -webkit-animation: l6 3s linear infinite; }
  .container .container2 .l6::before {
    content: "";
    display: block;
    width: 30px;
    height: 7px;
    background: gray;
    margin: 0px 80px;
    animation: l66 3s linear infinite;
    -moz-animation: l66 3s linear infinite;
    -webkit-animation: l66 3s linear infinite; }
  .container .container2 .l7 {
    position: absolute;
    width: 162px;
    height: 7px;
    background: #00aaef;
    margin: 212px 21px;
    animation: l7 3s linear infinite;
    -moz-animation: l7 3s linear infinite;
    -webkit-animation: l7 3s linear infinite; }
  .container .container2 .d1 {
    position: absolute;
    width: 201px;
    height: 50px;
    background: #e35a5a;
    animation: d1 3s linear infinite;
    -moz-animation: d1 3s linear infinite;
    -webkit-animation: d1 3s linear infinite; }
  .container .container2 .d2 {
    position: absolute;
    width: 201px;
    height: 30px;
    background: #df9935;
    margin: 50px 0;
    animation: d2 3s linear infinite;
    -moz-animation: d2 3s linear infinite;
    -webkit-animation: d2 3s linear infinite; }
  .container .container2 .d3 {
    position: absolute;
    width: 40px;
    height: 12px;
    background: white;
    margin: 57px 5px;
    animation: d3 3s linear infinite;
    -moz-animation: d3 3s linear infinite;
    -webkit-animation: d3 3s linear infinite; }
  .container .container2 .d3::before {
    content: "";
    display: block;
    width: 40px;
    height: 12px;
    background: white;
    margin: 0px 75px;
    animation: d3 3s linear infinite;
    -moz-animation: d3 3s linear infinite;
    -webkit-animation: d3 3s linear infinite; }
  .container .container2 .d3::after {
    content: "";
    display: block;
    width: 40px;
    height: 12px;
    background: white;
    margin: -12px 150px;
    animation: d3 3s linear infinite;
    -moz-animation: d3 3s linear infinite;
    -webkit-animation: d3 3s linear infinite; }
  .container .container2 .d4 {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #e35a5a;
    margin: 121px 5px;
    animation: d5 3s linear infinite;
    -moz-animation: d5 3s linear infinite;
    -webkit-animation: d5 3s linear infinite; }
  .container .container2 .d4::before {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    background: #df9935;
    margin: 0px 75px;
    animation: d5 3s linear infinite;
    -moz-animation: d5 3s linear infinite;
    -webkit-animation: d5 3s linear infinite; }
  .container .container2 .d4::after {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    background: #00aaef;
    margin: -40px 152px;
    animation: d5 3s linear infinite;
    -moz-animation: d5 3s linear infinite;
    -webkit-animation: d5 3s linear infinite; }
  .container .container2 .d5 {
    position: absolute;
    width: 201px;
    height: 50px;
    background: #00aaef;
    margin: 199px 0px;
    animation: d7 3s linear infinite;
    -moz-animation: d7 3s linear infinite;
    -webkit-animation: d7 3s linear infinite; }

@-webkit-keyframes l1 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  40% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  45% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  50% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  60% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 30px;
    height: 7px; } }

@keyframes l1 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  40% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  45% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  50% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  60% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 30px;
    height: 7px; } }

@-webkit-keyframes l2 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  45% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  50% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  60% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 50px;
    height: 7px; } }

@keyframes l2 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  45% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  50% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  60% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 50px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 50px;
    height: 7px; } }

@-webkit-keyframes l3 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  50% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  60% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 21px;
    height: 7px; } }

@keyframes l3 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  50% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  60% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 21px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 21px;
    height: 7px; } }

@-webkit-keyframes l4 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  50% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  60% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 70px;
    height: 7px; } }

@keyframes l4 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  50% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  60% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 70px;
    height: 7px; } }

@-webkit-keyframes l5 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  50% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  60% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 40px;
    height: 7px; } }

@keyframes l5 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  50% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  60% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 40px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 40px;
    height: 7px; } }

@-webkit-keyframes l6 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  50% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  60% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 70px;
    height: 7px; } }

@keyframes l6 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  50% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  60% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  70% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 70px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 70px;
    height: 7px; } }

@-webkit-keyframes l66 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  50% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  60% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  70% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 30px;
    height: 7px; } }

@keyframes l66 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  50% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  60% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  70% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  80% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 30px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 30px;
    height: 7px; } }

@-webkit-keyframes l7 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  50% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  60% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  70% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  80% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 132px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 132px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 132px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 132px;
    height: 7px; } }

@keyframes l7 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0;
    width: 0;
    height: 7; }
  35% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  40% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  45% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  50% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  60% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  70% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  80% {
    opacity: 0;
    width: 0px;
    height: 7px; }
  85% {
    opacity: 1;
    width: 132px;
    height: 7px; }
  90% {
    opacity: 1;
    width: 132px;
    height: 7px; }
  95% {
    opacity: 1;
    width: 132px;
    height: 7px; }
  100% {
    opacity: 1;
    width: 132px;
    height: 7px; } }

@-webkit-keyframes d1 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  35% {
    opacity: 1; }
  40% {
    opacity: 1; }
  45% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 1; }
  70% {
    opacity: 1; }
  80% {
    opacity: 1; }
  85% {
    opacity: 1; }
  90% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes d1 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  35% {
    opacity: 1; }
  40% {
    opacity: 1; }
  45% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 1; }
  70% {
    opacity: 1; }
  80% {
    opacity: 1; }
  85% {
    opacity: 1; }
  90% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@-webkit-keyframes d2 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  35% {
    opacity: 0; }
  40% {
    opacity: 1; }
  45% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 1; }
  70% {
    opacity: 1; }
  80% {
    opacity: 1; }
  85% {
    opacity: 1; }
  90% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes d2 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  35% {
    opacity: 0; }
  40% {
    opacity: 1; }
  45% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 1; }
  70% {
    opacity: 1; }
  80% {
    opacity: 1; }
  85% {
    opacity: 1; }
  90% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@-webkit-keyframes d3 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  35% {
    opacity: 0; }
  40% {
    opacity: 0; }
  45% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 1; }
  70% {
    opacity: 1; }
  80% {
    opacity: 1; }
  85% {
    opacity: 1; }
  90% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes d3 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  35% {
    opacity: 0; }
  40% {
    opacity: 0; }
  45% {
    opacity: 1; }
  50% {
    opacity: 1; }
  60% {
    opacity: 1; }
  70% {
    opacity: 1; }
  80% {
    opacity: 1; }
  85% {
    opacity: 1; }
  90% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@-webkit-keyframes d5 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  35% {
    opacity: 0; }
  40% {
    opacity: 0; }
  45% {
    opacity: 0; }
  50% {
    opacity: 0; }
  60% {
    opacity: 1; }
  70% {
    opacity: 1; }
  80% {
    opacity: 1; }
  85% {
    opacity: 1; }
  90% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes d5 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  35% {
    opacity: 0; }
  40% {
    opacity: 0; }
  45% {
    opacity: 0; }
  50% {
    opacity: 0; }
  60% {
    opacity: 1; }
  70% {
    opacity: 1; }
  80% {
    opacity: 1; }
  85% {
    opacity: 1; }
  90% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@-webkit-keyframes d7 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  35% {
    opacity: 0; }
  40% {
    opacity: 0; }
  45% {
    opacity: 0; }
  50% {
    opacity: 0; }
  60% {
    opacity: 0; }
  70% {
    opacity: 0; }
  80% {
    opacity: 0; }
  85% {
    opacity: 1; }
  90% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes d7 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  35% {
    opacity: 0; }
  40% {
    opacity: 0; }
  45% {
    opacity: 0; }
  50% {
    opacity: 0; }
  60% {
    opacity: 0; }
  70% {
    opacity: 0; }
  80% {
    opacity: 0; }
  85% {
    opacity: 1; }
  90% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 1; } }
  @media (max-width: 599px) {
    .container .wrapper3 {
      margin-top: 350px;
      margin-left: 15px; } }
  .container .wrapper3 .btn {
    align-items: center;
    background-color: #b4b6b8;
    border: 0 solid #fff;
    border-radius: 4px;
    display: -webkit-inline-box;
    display: -webkit-flex;
    padding: 8px 16px;
    padding: auto;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .container .wrapper3 .btn:hover {
    background-color: #000000;
    cursor: pointer; }
  .container .wrapper3 .btn:hover span {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
    opacity: 1;
    position: relative; }
  .container .wrapper3 .btn span {
    color: #fff;
    font-size: 10px;
    -webkit-transform: translateX(320px);
    transform: translateX(320px);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 0;
    position: fixed; }
  @media (max-width: 599px) {
    .container .wrapper4 {
      margin-left: 15px; } }
  @media (max-width: 599px) {
    .container .wrapper5 {
      margin-left: 15px; } }

.image-cover2 {
  margin-top: 140px; }
  @media (max-width: 599px) {
    .image-cover2 {
      margin-top: 0px; } }

.hover-content .title {
  font-size: 24px;
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  line-height: 100%; }

.hover-content .body {
  text-align: center;
  color: white;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  line-height: 100%; }

.image-container .hover-content {
  background: rgba(15, 20, 30, 0.95) !important; }

.footer {
  bottom: 0;
  width: 100%; }

