
.container {
  width: 1280px;
  min-width: 1280px;
// width: 100%;
// min-width: 100%;
  margin: 0 auto;
  height: 100%;
  @media (max-width: 599px) {
    width: 100%;
    min-width: 100%;
  }
  .wrapper {
    padding: 0 48px;
    @media (max-width: 599px) {
      padding: 0 0px 0 0px;
    }
    .home {
      display: flex;
      justify-content: center;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
      p {
        font-size: 0.75rem;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-weight: 500;
      }
      h5 {
        // margin-top: 260px;
        font-size: 1.375rem;
        font-weight: 600;
        // padding-right: 360px;
        @media (max-width: 599px) {
          font-size: 16px;
          padding-right: 0px;
        }
        ul {
          list-style-type:square;
          color: black;
        }
      }
      .Title-font{
        font-size: 4rem;
        @media (max-width: 599px) {
          font-size: 1.5rem;
        }
      }
      .Title-font-2{
        font-size: 4rem;
        @media (max-width: 599px) {
          font-size: 1.5rem;
        }
      }
      .Title-font-2:hover{
        text-decoration: underline;
      }
    }
  }
  .container2{
    .wrapper2{
      width:50%;
      float: right;
      margin-top: -500px;
      @media (max-width: 599px) {
        width: 100%;
        margin-top: -720px;
        }
    }
    .compu::before{
      content:"";
      display:block;
     border-bottom: 1px solid transparent;
      margin:395px 162px
    }
    .a{
      position:absolute;
      height:271px;
      background:#212121;
      margin:-770px 21px;
      @media (max-width: 599px) {
        margin: -770px 60px;
      }
    }
    .a::before{
      content:"</>";
      font-family:verdana;
      color:white;
      display:block;
      width:201px;
      height:21px;
      background:rgba(0,0,0,.3);
    }
    .b{
      position:absolute;
      height:271px;
      background:#ebf4ff;
     margin:-770px 231px;
     @media (max-width: 599px) {
      margin: -500px 60px;
    }
    }
    .b::before{
      content:"";
      color:black;
      display:block;
      width:201px;
      height:21px;
      background:rgba(0,0,0,.3);
      @media (max-width: 599px) {
      }
    }  
    .l1{
      position:absolute;
      width:30px;
      height:7px;
      background:#e35a5a;
      margin:30px 21px;
      animation: l1 3s linear infinite;
      -moz-animation: l1 3s linear infinite;
       -webkit-animation: l1 3s linear infinite;
    }
    .l2{
      position:absolute;
      width:50px;
      height:7px;
      background:#df9935;
      margin: 40px 21px;
      animation: l2 3s linear infinite;
      -moz-animation: l2 3s linear infinite;
       -webkit-animation: l2 3s linear infinite;
    }
    .l3{
      position:absolute;
      width:21px;
      height:7px;
      background:white;
      margin:50px 21px;
      animation: l3 3s linear infinite;
      -moz-animation: l3 3s linear infinite;
       -webkit-animation: l3 3s linear infinite;
    }
    .l4{
      position:absolute;
      width:70px;
      height:7px;
      background:#9e9e9e;
      margin: 83px 19px;
      animation: l4 3s linear infinite;
      -moz-animation: l4 3s linear infinite;
       -webkit-animation: l4 3s linear infinite;
    }
    .l4::before{
      content:"";
      display:block;
      width:70px;
      height:7px;
      background:#9e9e9e;
      margin: 0px 90px;
      animation: l4 3s linear infinite;
      -moz-animation: l4 3s linear infinite;
       -webkit-animation: l4 3s linear infinite;
    }
    .l5{
      position:absolute;
     
      width:40px;
      height:7px;
      background:#e35a5a;
      margin:121px 21px;
       animation: l5 3s linear infinite;
      -moz-animation: l5 3s linear infinite;
       -webkit-animation: l5 3s linear infinite;
    }
    .l5::before{
      content:"";
      display:block;
      width:40px;
      height:7px;
      background:#df9935;
      margin: 0px 60px;
      animation: l5 3s linear infinite;
      -moz-animation: l5 3s linear infinite;
       -webkit-animation: l5 3s linear infinite;
    }
    .l5::after{
      content:"";
      display:block;
      width:40px;
      height:7px;
      background:#00aaef;
      margin: -7px 121px;
      animation: l5 3s linear infinite;
      -moz-animation: l5 3s linear infinite;
       -webkit-animation: l5 3s linear infinite;
    }
    
    .l6{
      position:absolute;
      width:70px;
      height:7px;
      background:gray;
      margin:152px 21px;
       animation: l6 3s linear infinite;
      -moz-animation: l6 3s linear infinite;
       -webkit-animation: l6 3s linear infinite;
    }
    .l6::before{
      content:"";
     display:block;
      width:30px;
      height:7px;
      background:gray;
      margin:0px 80px;
      animation: l66 3s linear infinite;
      -moz-animation: l66 3s linear infinite;
       -webkit-animation: l66 3s linear infinite;
    }
    
    .l7{
      position:absolute;
      width:162px;
      height:7px;
      background:#00aaef;
      margin:212px 21px;
      animation: l7 3s linear infinite;
      -moz-animation: l7 3s linear infinite;
       -webkit-animation: l7 3s linear infinite;
    }
    .d1{
      position:absolute;
      width:201px;
      height:50px;
      background:#e35a5a;
      animation: d1 3s linear infinite;
      -moz-animation: d1 3s linear infinite;
       -webkit-animation: d1 3s linear infinite;
    }
    .d2{
      position:absolute;
      width:201px;
      height:30px;
      background:#df9935;
      margin:50px 0;
      animation: d2 3s linear infinite;
      -moz-animation: d2 3s linear infinite;
       -webkit-animation: d2 3s linear infinite;
    }
    .d3{
      position:absolute;
      width:40px;
      height:12px;
      background:white;
      margin:57px 5px;
       animation: d3 3s linear infinite;
      -moz-animation: d3 3s linear infinite;
       -webkit-animation: d3 3s linear infinite;
    }
    .d3::before{
      content:"";
      display:block;
      width:40px;
      height:12px;
      background:white;
      margin:0px 75px;
      animation: d3 3s linear infinite;
      -moz-animation: d3 3s linear infinite;
       -webkit-animation: d3 3s linear infinite;
    }
    .d3::after{
      content:"";
      display:block;
      width:40px;
      height:12px;
      background:white;
      margin:-12px 150px;
      animation: d3 3s linear infinite;
      -moz-animation: d3 3s linear infinite;
       -webkit-animation: d3 3s linear infinite;
    }
    .d4{
      position:absolute;
      width:40px;
      height:40px;
      background:#e35a5a;
      margin:121px 5px;
       animation: d5 3s linear infinite;
      -moz-animation: d5 3s linear infinite;
       -webkit-animation: d5 3s linear infinite;
    }
    .d4::before{
      content:"";
     display:block;
      width:40px;
      height:40px;
      background:#df9935;
      margin:0px 75px;
       animation: d5 3s linear infinite;
      -moz-animation: d5 3s linear infinite;
       -webkit-animation: d5 3s linear infinite;
    }
    .d4::after{
      content:"";
     display:block;
      width:40px;
      height:40px;
      background:#00aaef;
      margin:-40px 152px;
       animation: d5 3s linear infinite;
      -moz-animation: d5 3s linear infinite;
       -webkit-animation: d5 3s linear infinite;
    }
    .d5{
      position:absolute;
      width:201px;
      height:50px;
      background:#00aaef;
      margin:199px 0px;
      animation: d7 3s linear infinite;
      -moz-animation: d7 3s linear infinite;
       -webkit-animation: d7 3s linear infinite;
    }
    @-webkit-keyframes l1 {
      0% {opacity: 0;}
      25% {opacity: 0;width:0;height:7;}
      35% {opacity: 1;width:30px;height:7px;}
      40% {opacity: 1;width:30px;height:7px;
      }
       45% {opacity: 1;
        width:30px;
        height:7px;
      }
       50% {opacity: 1;
        width:30px;
        height:7px;
      }
      60% {opacity: 1;
        width:30px;
        height:7px;
      }
      70% {opacity: 1;
        width:30px;
        height:7px;}
       80% {opacity: 1;
         width:30px;
        height:7px;}
       85% {opacity: 1;
         width:30px;
        height:7px;}
       90% {opacity: 1;
         width:30px;
        height:7px;}
       95% {opacity: 1;
         width:30px;
        height:7px;}
      100% {opacity: 1;  width:30px;
        height:7px;}
    }
    @-moz-keyframes l1 {
      0% {opacity: 0;}
      25% {opacity: 0;width:0;height:7;}
      35% {opacity: 1;width:30px;height:7px;}
      40% {opacity: 1;width:30px;height:7px;
      }
       45% {opacity: 1;
        width:30px;
        height:7px;
      }
       50% {opacity: 1;
        width:30px;
        height:7px;
      }
      60% {opacity: 1;
        width:30px;
        height:7px;
      }
      70% {opacity: 1;
        width:30px;
        height:7px;}
       80% {opacity: 1;
         width:30px;
        height:7px;}
       85% {opacity: 1;
         width:30px;
        height:7px;}
       90% {opacity: 1;
         width:30px;
        height:7px;}
       95% {opacity: 1;
         width:30px;
        height:7px;}
      100% {opacity: 1;  width:30px;
        height:7px;}
    }
    @keyframes l1 {
      0% {opacity: 0;}
      25% {opacity: 0;width:0;height:7;}
      35% {opacity: 1;width:30px;height:7px;}
      40% {opacity: 1;width:30px;height:7px;
      }
       45% {opacity: 1;
        width:30px;
        height:7px;
      }
       50% {opacity: 1;
        width:30px;
        height:7px;
      }
      60% {opacity: 1;
        width:30px;
        height:7px;
      }
      70% {opacity: 1;
        width:30px;
        height:7px;}
       80% {opacity: 1;
         width:30px;
        height:7px;}
       85% {opacity: 1;
         width:30px;
        height:7px;}
       90% {opacity: 1;
         width:30px;
        height:7px;}
       95% {opacity: 1;
         width:30px;
        height:7px;}
      100% {opacity: 1;  width:30px;
        height:7px;}
    }
    @-webkit-keyframes l2{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 1;
        width:50px;
        height:7px;
      }
       45% {opacity: 1;
        width:50px;
        height:7px;
      }
       50% {opacity: 1;
        width:50px;
        height:7px;
      }
      60% {opacity: 1;
        width:50px;
        height:7px;
      }
      70% {opacity: 1;
        width:50px;
        height:7px;}
       80% {opacity: 1;
         width:50px;
        height:7px;}
       85% {opacity: 1;
         width:50px;
        height:7px;}
       90% {opacity: 1;
         width:50px;
        height:7px;}
       95% {opacity: 1;
         width:50px;
        height:7px;}
      100% {opacity: 1;  width:50px;
        height:7px;}
    }
    @-moz-keyframes l2{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 1;
        width:50px;
        height:7px;
      }
       45% {opacity: 1;
        width:50px;
        height:7px;
      }
       50% {opacity: 1;
        width:50px;
        height:7px;
      }
      60% {opacity: 1;
        width:50px;
        height:7px;
      }
      70% {opacity: 1;
        width:50px;
        height:7px;}
       80% {opacity: 1;
         width:50px;
        height:7px;}
       85% {opacity: 1;
         width:50px;
        height:7px;}
       90% {opacity: 1;
         width:50px;
        height:7px;}
       95% {opacity: 1;
         width:50px;
        height:7px;}
      100% {opacity: 1;  width:50px;
        height:7px;}
    }
    @keyframes l2{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 1;
        width:50px;
        height:7px;
      }
       45% {opacity: 1;
        width:50px;
        height:7px;
      }
       50% {opacity: 1;
        width:50px;
        height:7px;
      }
      60% {opacity: 1;
        width:50px;
        height:7px;
      }
      70% {opacity: 1;
        width:50px;
        height:7px;}
       80% {opacity: 1;
         width:50px;
        height:7px;}
       85% {opacity: 1;
         width:50px;
        height:7px;}
       90% {opacity: 1;
         width:50px;
        height:7px;}
       95% {opacity: 1;
         width:50px;
        height:7px;}
      100% {opacity: 1;  width:50px;
        height:7px;}
    }
    @-webkit-keyframes l3{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 1;
        width:21px;
        height:7px;
      }
       50% {opacity: 1;
        width:21px;
        height:7px;
      }
      60% {opacity: 1;
        width:21px;
        height:7px;
      }
      70% {opacity: 1;
        width:21px;
        height:7px;}
       80% {opacity: 1;
         width:21px;
        height:7px;}
       85% {opacity: 1;
         width:21px;
        height:7px;}
       90% {opacity: 1;
         width:21px;
        height:7px;}
       95% {opacity: 1;
         width:21px;
        height:7px;}
      100% {opacity: 1;  width:21px;
        height:7px;}
    }
    @-moz-keyframes l3{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 1;
        width:21px;
        height:7px;
      }
       50% {opacity: 1;
        width:21px;
        height:7px;
      }
      60% {opacity: 1;
        width:21px;
        height:7px;
      }
      70% {opacity: 1;
        width:21px;
        height:7px;}
       80% {opacity: 1;
         width:21px;
        height:7px;}
       85% {opacity: 1;
         width:21px;
        height:7px;}
       90% {opacity: 1;
         width:21px;
        height:7px;}
       95% {opacity: 1;
         width:21px;
        height:7px;}
      100% {opacity: 1;  width:21px;
        height:7px;}
    }
    @keyframes l3{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 1;
        width:21px;
        height:7px;
      }
       50% {opacity: 1;
        width:21px;
        height:7px;
      }
      60% {opacity: 1;
        width:21px;
        height:7px;
      }
      70% {opacity: 1;
        width:21px;
        height:7px;}
       80% {opacity: 1;
         width:21px;
        height:7px;}
       85% {opacity: 1;
         width:21px;
        height:7px;}
       90% {opacity: 1;
         width:21px;
        height:7px;}
       95% {opacity: 1;
         width:21px;
        height:7px;}
      100% {opacity: 1;  width:21px;
        height:7px;}
    }
    @-webkit-keyframes l4{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 1;
        width:70px;
        height:7px;
      }
      60% {opacity: 1;
        width:70px;
        height:7px;
      }
      70% {opacity: 1;
        width:70px;
        height:7px;}
       80% {opacity: 1;
         width:70px;
        height:7px;}
       85% {opacity: 1;
         width:70px;
        height:7px;}
       90% {opacity: 1;
         width:70px;
        height:7px;}
       95% {opacity: 1;
         width:70px;
        height:7px;}
      100% {opacity: 1;  width:70px;
        height:7px;}
    }
    @-moz-keyframes l4{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 1;
        width:70px;
        height:7px;
      }
      60% {opacity: 1;
        width:70px;
        height:7px;
      }
      70% {opacity: 1;
        width:70px;
        height:7px;}
       80% {opacity: 1;
         width:70px;
        height:7px;}
       85% {opacity: 1;
         width:70px;
        height:7px;}
       90% {opacity: 1;
         width:70px;
        height:7px;}
       95% {opacity: 1;
         width:70px;
        height:7px;}
      100% {opacity: 1;  width:70px;
        height:7px;}
    }
    @keyframes l4{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 1;
        width:70px;
        height:7px;
      }
      60% {opacity: 1;
        width:70px;
        height:7px;
      }
      70% {opacity: 1;
        width:70px;
        height:7px;}
       80% {opacity: 1;
         width:70px;
        height:7px;}
       85% {opacity: 1;
         width:70px;
        height:7px;}
       90% {opacity: 1;
         width:70px;
        height:7px;}
       95% {opacity: 1;
         width:70px;
        height:7px;}
      100% {opacity: 1;  width:70px;
        height:7px;}
    }
    @-webkit-keyframes  l5{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 1;
        width:40px;
        height:7px;
      }
      70% {opacity: 1;
        width:40px;
        height:7px;}
       80% {opacity: 1;
         width:40px;
        height:7px;}
       85% {opacity: 1;
         width:40px;
        height:7px;}
       90% {opacity: 1;
         width:40px;
        height:7px;}
       95% {opacity: 1;
         width:40px;
        height:7px;}
      100% {opacity: 1;  width:40px;
        height:7px;}
    }
    @-moz-keyframes  l5{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 1;
        width:40px;
        height:7px;
      }
      70% {opacity: 1;
        width:40px;
        height:7px;}
       80% {opacity: 1;
         width:40px;
        height:7px;}
       85% {opacity: 1;
         width:40px;
        height:7px;}
       90% {opacity: 1;
         width:40px;
        height:7px;}
       95% {opacity: 1;
         width:40px;
        height:7px;}
      100% {opacity: 1;  width:40px;
        height:7px;}
    }
    @keyframes  l5{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 1;
        width:40px;
        height:7px;
      }
      70% {opacity: 1;
        width:40px;
        height:7px;}
       80% {opacity: 1;
         width:40px;
        height:7px;}
       85% {opacity: 1;
         width:40px;
        height:7px;}
       90% {opacity: 1;
         width:40px;
        height:7px;}
       95% {opacity: 1;
         width:40px;
        height:7px;}
      100% {opacity: 1;  width:40px;
        height:7px;}
    }
    @-webkit-keyframes l6 {
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 0;
        width:0px;
        height:7px;
      }
      70% {opacity: 1;
        width:70px;
        height:7px;}
       80% {opacity: 1;
         width:70px;
        height:7px;}
       85% {opacity: 1;
         width:70px;
        height:7px;}
       90% {opacity: 1;
         width:70px;
        height:7px;}
       95% {opacity: 1;
         width:70px;
        height:7px;}
      100% {opacity: 1;  width:70px;
        height:7px;}
    }
    @-moz-keyframes l6 {
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 0;
        width:0px;
        height:7px;
      }
      70% {opacity: 1;
        width:70px;
        height:7px;}
       80% {opacity: 1;
         width:70px;
        height:7px;}
       85% {opacity: 1;
         width:70px;
        height:7px;}
       90% {opacity: 1;
         width:70px;
        height:7px;}
       95% {opacity: 1;
         width:70px;
        height:7px;}
      100% {opacity: 1;  width:70px;
        height:7px;}
    }
    @keyframes l6 {
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 0;
        width:0px;
        height:7px;
      }
      70% {opacity: 1;
        width:70px;
        height:7px;}
       80% {opacity: 1;
         width:70px;
        height:7px;}
       85% {opacity: 1;
         width:70px;
        height:7px;}
       90% {opacity: 1;
         width:70px;
        height:7px;}
       95% {opacity: 1;
         width:70px;
        height:7px;}
      100% {opacity: 1;  width:70px;
        height:7px;}
    }
    @-webkit-keyframes l66 {
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 0;
        width:0px;
        height:7px;
      }
      70% {opacity: 0;
        width:0px;
        height:7px;}
       80% {opacity: 1;
         width:30px;
        height:7px;}
       85% {opacity: 1;
         width:30px;
        height:7px;}
       90% {opacity: 1;
         width:30px;
        height:7px;}
       95% {opacity: 1;
         width:30px;
        height:7px;}
      100% {opacity: 1;  width:30px;
        height:7px;}
    }
    @-moz-keyframes l66 {
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 0;
        width:0px;
        height:7px;
      }
      70% {opacity: 0;
        width:0px;
        height:7px;}
       80% {opacity: 1;
         width:30px;
        height:7px;}
       85% {opacity: 1;
         width:30px;
        height:7px;}
       90% {opacity: 1;
         width:30px;
        height:7px;}
       95% {opacity: 1;
         width:30px;
        height:7px;}
      100% {opacity: 1;  width:30px;
        height:7px;}
    }
    @keyframes l66 {
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 0;
        width:0px;
        height:7px;
      }
      70% {opacity: 0;
        width:0px;
        height:7px;}
       80% {opacity: 1;
         width:30px;
        height:7px;}
       85% {opacity: 1;
         width:30px;
        height:7px;}
       90% {opacity: 1;
         width:30px;
        height:7px;}
       95% {opacity: 1;
         width:30px;
        height:7px;}
      100% {opacity: 1;  width:30px;
        height:7px;}
    }
    @-webkit-keyframes  l7{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 0;
        width:0px;
        height:7px;
      }
      70% {opacity: 0;
        width:0px;
        height:7px;}
       80% {opacity: 0;
         width:0px;
        height:7px;}
      
       85% {opacity: 1;
         width:132px;
        height:7px;}
       90% {opacity: 1;
         width:132px;
        height:7px;}
       95% {opacity: 1;
         width:132px;
        height:7px;}
      100% {opacity: 1;  width:132px;
        height:7px;}
    }
    @-moz-keyframes  l7{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 0;
        width:0px;
        height:7px;
      }
      70% {opacity: 0;
        width:0px;
        height:7px;}
       80% {opacity: 0;
         width:0px;
        height:7px;}
      
       85% {opacity: 1;
         width:132px;
        height:7px;}
       90% {opacity: 1;
         width:132px;
        height:7px;}
       95% {opacity: 1;
         width:132px;
        height:7px;}
      100% {opacity: 1;  width:132px;
        height:7px;}
    }
    @keyframes  l7{
      0% {opacity: 0;}
      25% {opacity: 0;
        width:0;
        height:7;}
      
      35% {opacity: 0;
        width:0px;
        height:7px;
      }
      40% {opacity: 0;
        width:0px;
        height:7px;
      }
       45% {opacity: 0;
        width:0px;
        height:7px;
      }
       50% {opacity: 0;
        width:0px;
        height:7px;
      }
      60% {opacity: 0;
        width:0px;
        height:7px;
      }
      70% {opacity: 0;
        width:0px;
        height:7px;}
       80% {opacity: 0;
         width:0px;
        height:7px;}
      
       85% {opacity: 1;
         width:132px;
        height:7px;}
       90% {opacity: 1;
         width:132px;
        height:7px;}
       95% {opacity: 1;
         width:132px;
        height:7px;}
      100% {opacity: 1;  width:132px;
        height:7px;}
    }
    @-webkit-keyframes d1 {
      0% {opacity: 0;}
      25% {opacity: 0;
       }
      
      35% {opacity: 1;
       
      }
      40% {opacity: 1;
        
      }
       45% {opacity: 1;
        
      }
       50% {opacity: 1;
        
      }
      60% {opacity: 1;
        
      }
      70% {opacity: 1;
        }
       80% {opacity: 1;
       }
       85% {opacity: 1;
         }
       90% {opacity: 1;
         }
       95% {opacity: 1;
         }
      100% {opacity: 1;  
    }
    }
    @-moz-keyframes d1 {
      0% {opacity: 0;}
      25% {opacity: 0;
       }
      
      35% {opacity: 1;
       
      }
      40% {opacity: 1;
        
      }
       45% {opacity: 1;
        
      }
       50% {opacity: 1;
        
      }
      60% {opacity: 1;
        
      }
      70% {opacity: 1;
        }
       80% {opacity: 1;
       }
       85% {opacity: 1;
         }
       90% {opacity: 1;
         }
       95% {opacity: 1;
         }
      100% {opacity: 1;  
    }
    }
    @keyframes d1 {
      0% {opacity: 0;}
      25% {opacity: 0;
       }
      
      35% {opacity: 1;
       
      }
      40% {opacity: 1;
        
      }
       45% {opacity: 1;
        
      }
       50% {opacity: 1;
        
      }
      60% {opacity: 1;
        
      }
      70% {opacity: 1;
        }
       80% {opacity: 1;
       }
       85% {opacity: 1;
         }
       90% {opacity: 1;
         }
       95% {opacity: 1;
         }
      100% {opacity: 1;  
    }
    }
      @-webkit-keyframes d2{
      0% {opacity: 0;}
      25% {opacity: 0; }
      
      35% {opacity: 0;}
      40% {opacity: 1;}
       45% {opacity: 1;}
       50% {opacity: 1;}
      60% {opacity: 1;}
      70% {opacity: 1;}
       80% {opacity: 1;}
       85% {opacity: 1;
        }
        90% {opacity: 1;}
        
       95% {opacity: 1;
         }
      100% {opacity: 1;  }
    }
    @-moz-keyframes d2{
      0% {opacity: 0;}
      25% {opacity: 0; }
      
      35% {opacity: 0;}
      40% {opacity: 1;}
       45% {opacity: 1;}
       50% {opacity: 1;}
      60% {opacity: 1;}
      70% {opacity: 1;}
       80% {opacity: 1;}
       85% {opacity: 1;
        }
        90% {opacity: 1;}
        
       95% {opacity: 1;
         }
      100% {opacity: 1;  }
    }
    @keyframes d2{
      0% {opacity: 0;}
      25% {opacity: 0; }
      
      35% {opacity: 0;}
      40% {opacity: 1;}
       45% {opacity: 1;}
       50% {opacity: 1;}
      60% {opacity: 1;}
      70% {opacity: 1;}
       80% {opacity: 1;}
       85% {opacity: 1;
        }
        90% {opacity: 1;}
        
       95% {opacity: 1;
         }
      100% {opacity: 1;  }
    }
     @-webkit-keyframes d3{
      0% {opacity: 0;}
      25% {opacity: 0;}
      
      35% {opacity: 0;}
      40% {opacity: 0;
        
      }
       45% {opacity: 1;
       
      }
       50% {opacity: 1;
        
      }
      60% {opacity: 1;
        
      }
      70% {opacity: 1;
      }
       80% {opacity: 1;
         }
       85% {opacity: 1;
        }
       90% {opacity: 1;
       }
       95% {opacity: 1;
         }
      100% {opacity: 1;  }
    }
    @-moz-keyframes d3{
      0% {opacity: 0;}
      25% {opacity: 0;}
      
      35% {opacity: 0;}
      40% {opacity: 0;
        
      }
       45% {opacity: 1;
       
      }
       50% {opacity: 1;
        
      }
      60% {opacity: 1;
        
      }
      70% {opacity: 1;
      }
       80% {opacity: 1;
         }
       85% {opacity: 1;
        }
       90% {opacity: 1;
       }
       95% {opacity: 1;
         }
      100% {opacity: 1;  }
    }
    @keyframes d3{
      0% {opacity: 0;}
      25% {opacity: 0;}
      
      35% {opacity: 0;}
      40% {opacity: 0;
        
      }
       45% {opacity: 1;
       
      }
       50% {opacity: 1;
        
      }
      60% {opacity: 1;
        
      }
      70% {opacity: 1;
      }
       80% {opacity: 1;
         }
       85% {opacity: 1;
        }
       90% {opacity: 1;
       }
       95% {opacity: 1;
         }
      100% {opacity: 1;  }
    }
    
    @-webkit-keyframes d5 {
      0% {opacity: 0;}
      25% {opacity: 0;
        }
      
      35% {opacity: 0;
        
      }
      40% {opacity: 0;
       
      }
       45% {opacity: 0;
       
      }
       50% {opacity: 0;
        
      }
      60% {opacity: 1;
        
      }
      70% {opacity: 1;
        }
       80% {opacity: 1;
       }
       85% {opacity: 1;
       }
       90% {opacity: 1;
       }
       95% {opacity: 1;
       }
      100% {opacity: 1; }
    }
    @-moz-keyframes d5 {
      0% {opacity: 0;}
      25% {opacity: 0;
        }
      
      35% {opacity: 0;
        
      }
      40% {opacity: 0;
       
      }
       45% {opacity: 0;
       
      }
       50% {opacity: 0;
        
      }
      60% {opacity: 1;
        
      }
      70% {opacity: 1;
        }
       80% {opacity: 1;
       }
       85% {opacity: 1;
       }
       90% {opacity: 1;
       }
       95% {opacity: 1;
       }
      100% {opacity: 1; }
    }
    @keyframes d5 {
      0% {opacity: 0;}
      25% {opacity: 0;
        }
      
      35% {opacity: 0;
        
      }
      40% {opacity: 0;
       
      }
       45% {opacity: 0;
       
      }
       50% {opacity: 0;
        
      }
      60% {opacity: 1;
        
      }
      70% {opacity: 1;
        }
       80% {opacity: 1;
       }
       85% {opacity: 1;
       }
       90% {opacity: 1;
       }
       95% {opacity: 1;
       }
      100% {opacity: 1; }
    }
    @-moz-keyframes d7 {
      0% {opacity: 0;}
      25% {opacity: 0;
        }
      
      35% {opacity: 0;
        
       
      }
      40% {opacity: 0;
       
      }
       45% {opacity: 0;
        
      }
       50% {opacity: 0;
        
      }
      60% {opacity: 0;
        
      }
      70% {opacity: 0;
       }
       80% {opacity: 0;
       }
      
       85% {opacity: 1;
         }
       90% {opacity: 1;
         }
       95% {opacity: 1;
         }
      100% {opacity: 1;  }
    }
    @-webkit-keyframes d7 {
      0% {opacity: 0;}
      25% {opacity: 0;
        }
      
      35% {opacity: 0;
        
       
      }
      40% {opacity: 0;
       
      }
       45% {opacity: 0;
        
      }
       50% {opacity: 0;
        
      }
      60% {opacity: 0;
        
      }
      70% {opacity: 0;
       }
       80% {opacity: 0;
       }
      
       85% {opacity: 1;
         }
       90% {opacity: 1;
         }
       95% {opacity: 1;
         }
      100% {opacity: 1;  }
    }
    @keyframes d7 {
      0% {opacity: 0;}
      25% {opacity: 0;
        }
      
      35% {opacity: 0;
        
       
      }
      40% {opacity: 0;
       
      }
       45% {opacity: 0;
        
      }
       50% {opacity: 0;
        
      }
      60% {opacity: 0;
        
      }
      70% {opacity: 0;
       }
       80% {opacity: 0;
       }
      
       85% {opacity: 1;
         }
       90% {opacity: 1;
         }
       95% {opacity: 1;
         }
      100% {opacity: 1;  }
    }
  }
  .wrapper3{
    @media (max-width: 599px) {
      margin-top: 350px;
      margin-left: 15px;
    }
    .btn {
        -webkit-box-align: center;
        align-items: center;
        background-color: #b4b6b8;
        border: 0 solid #fff;
        border-radius: 4px;
        display: -webkit-inline-box;
        display: -webkit-flex;
        padding: 8px 16px;
        padding: auto;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
      .btn:hover {
        background-color: #000000;
        cursor: pointer;
      }
      .btn:hover span {
        -webkit-transform: translateX(4px);
                transform: translateX(4px);
        opacity: 1;
        position: relative;
      }
      .btn span {
        color: #fff;
        font-size: 10px;
        -webkit-transform: translateX(320px);
                transform: translateX(320px);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        opacity: 0;
        position: fixed;
      }
  }
  .wrapper4{
    @media (max-width: 599px) {
      margin-left: 15px;
    }
  }
  .wrapper5{
    @media (max-width: 599px) {
      margin-left: 15px;
    }
  }
}
.image-cover2{
  margin-top: 140px;
  @media (max-width: 599px) {
    margin-top: 0px;
  }
}
.hover-content .title{
  font-size: 24px;
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  line-height: 100%;
}
.hover-content .body{
  text-align: center;
  color: white;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  line-height: 100%;
  }

  .image-container .hover-content {
      background: rgba(15,20,30,.95) !important;
  }
      

.footer {
  bottom:0;
  width: 100%;
}
