main{
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .largeElements{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    }
    .smallElements{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    }
    .smallElements{
    width: 60px;
    height: 60px;
    background-color: blue;
    }
    .largeElements{
    width: 180px;
    height: 180px;
    background-color: black;
    }